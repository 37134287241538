<template>
  <svg
    class="overflow-visible stroke-current"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 30"
    fill="none"
  >
    <path
      :stroke-width="props.strokeWidth"
      stroke-linecap="round"
      stroke-linejoin="round"
      vector-effect="non-scaling-stroke"
      d="m4 29 14-14L4 1"
    />
  </svg>
</template>

<script lang="ts" setup>
interface SubscriptionGlyphAngleRightProps {
  strokeWidth?: number | string
}

const props = withDefaults(defineProps<SubscriptionGlyphAngleRightProps>(), { strokeWidth: 1 })
</script>
